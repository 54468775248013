// import AllRoutes from "./Routes.js";
import { BrowserRouter as Router } from "react-router-dom";
import ContextProvider from "./context/ContextProvider";
import Notification from "./Components/user/Notification.jsx";
import Loading from "./Components/user/Loading.jsx";
import { lazy, Suspense } from "react";
import CheckConnection from "./Components/CheckConnection.jsx";
// import LazyLoading from "./Components/lazyLoading.jsx";
const AllRoutes = lazy(() => import("./Routes.js"));

function App() {
  // const [loading] = useState(true);

  return (
    <>
      <CheckConnection>
        <ContextProvider>
          <Loading />
          <Notification />
          <Router>
            <Suspense fallback={<div></div>}>
              <AllRoutes />
            </Suspense>
          </Router>
        </ContextProvider>
      </CheckConnection>
    </>
  );
}

export default App;
